<template>
<div style="text-align: left">
  <div class="block">
    <el-timeline>
      <el-timeline-item timestamp="2021-8-19" placement="top" icon="fa fa-hand-peace-o" color="blue">
        <el-card>
          <h4>项目开始</h4>
          <p>基于现成vue框架搭建一个简陋的博客系统；完成项代表蓝色小手，其中有一些规划放到时间线中</p>
        </el-card>
      </el-timeline-item>

      <el-timeline-item timestamp="2021-9-5" placement="top" icon="fa fa-hand-peace-o" color="blue">
        <el-card>
          <h4>网站后台搭建完成</h4>
          <p>前端框架基于vue+elementui，后端框架.netcore webapi+jwt。网站分前后端，前端发布文章及工具类，后台用于管理文章发布，基础表维护等</p>
        </el-card>
      </el-timeline-item>
      <el-timeline-item timestamp="2021-9-12" placement="top" icon="fa fa-hand-peace-o" color="blue">
        <el-card>
          <h4>发布文章，评论，留言开发基本结束</h4>
          <p>等待域名申请及服务器布置</p>
        </el-card>
      </el-timeline-item>
      <el-timeline-item timestamp="2021-9-17" placement="top" icon="fa fa-hand-peace-o" color="blue">
        <el-card>
          <h4>博客上线</h4>
          <p>布置到ubuntu系统，后续需优化评价回复</p>
        </el-card>
      </el-timeline-item>
      <el-timeline-item timestamp="2021-9-18" placement="top" icon="fa fa-hand-peace-o" color="blue">
        <el-card>
          <h4>优化首页数据加载速度</h4>
          <p></p>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>
.el-card p{
  margin-top: 5px;
}
</style>